<template>
  <validation-observer ref="simpleRules">
    <b-overlay :show="show" rounded="sm">
      <b-form>
        <b-card>
          <b-card-title
            ><h2>{{ $t("ProjectForm.Add_project") }}</h2>
          </b-card-title>
          <br />
          <b-row>
            <!--  name-->
            <b-col md="6">
              <b-form-group>
                <label class="required"
                  >{{ $t("ProjectForm.Project_Name") }}
                </label>
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="Project Name"
                >
                  <b-form-input
                    v-model="createProjectProfile.name"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('ProjectForm.Project_Name')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Project belongs to"
                rules="required"
              >
                <b-form-group :state="errors.length > 0 ? false : null">
                  <label class="required" for="owner_type"
                    >{{ $t("ProjectForm.belongs_to") }}
                  </label>

                  <v-select
                    id="State"
                    v-model="createProjectProfile.selectedOwnerType"
                    :options="
                      $store.state.locale.locale == 'ar'
                        ? createProjectProfile.OwnerTypeOptionArabic
                        : createProjectProfile.OwnerTypeOption
                    "
                    :selectable="
                      (option) => !option.value.includes('nothing_selected')
                    "
                    label="text"
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Country"
                rules="required"
              >
                <b-form-group :state="errors.length > 0 ? false : null">
                  <label class="required" for="Country"
                    >{{ $t("ProjectForm.Project_Country") }}
                  </label>

                  <v-select
                    id="Country"
                    v-model="createProjectProfile.selectedCountry"
                    :options="countries"
                    :selectable="
                      (option) => !option.value.includes('nothing_selected')
                    "
                    label="text"
                    @input="
                      retreiveStates(createProjectProfile.selectedCountry.value)
                    "
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Project state"
                rules="required"
              >
                <b-form-group :state="errors.length > 0 ? false : null">
                  <label class="required" for="State"
                    >{{ $t("ProjectForm.Project_state") }}
                  </label>

                  <v-select
                    id="State"
                    v-model="createProjectProfile.selectedState"
                    :options="statesOptions"
                    label="text"
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Project City"
                  rules="required"
                >
                  <label class="required" for="City"
                    >{{ $t("ProjectForm.Project_City") }}
                  </label>
                  <b-form-input
                    id="City"
                    v-model="createProjectProfile.city"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('ProjectForm.Project_City')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="highlights"
                  :rules="`required|max:${$maxHighlightsProfile}`"
                >
                  <label class="required" for="highlights"
                    >{{ $t("ProjectForm.Brief_overview") }}
                  </label>
                  <b-form-textarea
                    id="highlights"
                    v-model="createProjectProfile.highlights"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('ProjectForm.Brief_overview')"
                    rows="2"
                    max-rows="2"
                    no-auto-shrink
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Project owner description"
                  rules="required"
                >
                  <label class="required" for="Owner-Details"
                    >{{ $t("ProjectForm.owner_description") }}
                  </label>

                  <b-form-textarea
                    id="Owner-Details"
                    v-model="createProjectProfile.OwnerDetails"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('ProjectForm.owner_description')"
                    rows="3"
                    max-rows="3"
                    no-auto-shrink
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="description"
                  rules="required"
                >
                  <label class="required" for="description"
                    >{{ $t("ProjectForm.description_project") }}
                  </label>

                  <b-form-textarea
                    id="description"
                    v-model="createProjectProfile.description"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('ProjectForm.description_project_p')"
                    rows="3"
                    max-rows="3"
                    no-auto-shrink
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Industries"
                rules="required"
              >
                <b-form-group :state="errors.length > 0 ? false : null">
                  <label class="required" for="Industry"
                    >{{ $t("ProjectForm.industries_belong") }}
                  </label>
                  <div id="app">
                    <treeselect
                      v-model="createProjectProfile.industries"
                      :multiple="true"
                      label="Select Industries ."
                      :placeholder="$t('common.Select')"
                      :options="industries"
                      @input="handeTreeSelect"
                      :normalizer="
                        $store.state.locale.locale == 'ar'
                          ? normalizerArabic
                          : normalizer
                      "
                    />
                  </div>
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="6">
              <b-form-group>
                <label class="required" for="Assets"
                  >{{ $t("ProjectForm.Describe_physical_assets") }}
                </label>
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="Assets"
                >
                  <b-form-input
                    id="Assets"
                    v-model="createProjectProfile.assets"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('ProjectForm.Describe_physical_assets')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <label class="required" for="StafNumber"
                  >{{ $t("ProjectForm.people_need") }}
                </label>
                <validation-provider
                  #default="{ errors }"
                  rules="required|integer"
                  name="Staf number"
                >
                  <b-form-input
                    id="StafNumber"
                    v-model="createProjectProfile.staffNumber"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('ProjectForm.people_need')"
                    type="number"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <validation-provider
                #default="{ errors }"
                name="BudgetCurrency"
                rules="required"
              >
                <b-form-group :state="errors.length > 0 ? false : null">
                  <label class="required" for="BudgetCurrency"
                    >{{ $t("ProjectForm.Budget_Currency") }}
                  </label>

                  <v-select
                    id=""
                    v-model="createProjectProfile.budgetCurrency"
                    :options="currencies"
                    :selectable="
                      (option) => !option.value.includes('select_value')
                    "
                    label="text"
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <validation-provider
                #default="{ errors }"
                name="BudgeNumber"
                rules="required|integer"
              >
                <b-form-group :state="errors.length > 0 ? false : null">
                  <label class="required" for="BudgeNumber"
                    >{{ $t("ProjectForm.expected_budget") }}
                  </label>
                  <b-form-input
                    id="BudgeNumber"
                    v-model="createProjectProfile.budgetPrice"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('ProjectForm.expected_budget')"
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Project time"
                rules="required"
              >
                <b-form-group :state="errors.length > 0 ? false : null">
                  <label class="required" for="ProjectTime"
                    >{{ $t("ProjectForm.set_deadline") }}
                  </label>

                  <v-select
                    id="ProjectTime"
                    v-model="projectTimeValue"
                    :options="
                      $store.state.locale.locale == 'ar'
                        ? createProjectProfile.projectTimeOptionsArabic
                        : createProjectProfile.projectTimeOptions
                    "
                    :selectable="
                      (option) => !option.value.includes('select_value')
                    "
                    label="text"
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <b-row>
                <b-col
                  v-show="
                    projectTimeValue.value === '1' ||
                    projectTimeValue.value === '2'
                  "
                  md="6"
                >
                  <label class="required" for="example-datepicker"
                    >{{ $t("ProjectForm.Start_Date") }}
                  </label>
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="StartDate"
                  >
                    <b-form-datepicker
                      id="StartDate"
                      v-model="createProjectProfile.start"
                      :state="errors.length > 0 ? false : null"
                      class="mb-2"
                      :min="minDate"
                      :locale="$store.state.locale.locale"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
                <b-col v-if="projectTimeValue.value === '2'" md="6">
                  <label for="EndDate">{{ $t("ProjectForm.End_Date") }} </label>
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="EndDate"
                  >
                    <b-form-datepicker
                      id="EndDate"
                      v-model="createProjectProfile.end"
                      :state="errors.length > 0 ? false : null"
                      class="mb-2"
                      :min="minDate2"
                      :locale="$store.state.locale.locale"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
            </b-col>

            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Category"
                rules="required"
              >
                <b-form-group :state="errors.length > 0 ? false : null">
                  <label class="required" for="Category">{{
                    $t("ProjectForm.needs_from_sharing")
                  }}</label>
                  <v-select
                    id="Category"
                    v-model="createProjectProfile.selectedCategory"
                    :options="categories"
                    :selectable="
                      (option) => !option.value.includes('nothing_selected')
                    "
                    label="text"
                    @input="resetDataSaleType()"
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              md="6"
              v-if="
                createProjectProfile.selectedCategory.value ==
                '62b40ba9956d948f90a3941e'
              "
            >
              <validation-provider
                #default="{ errors }"
                name="sale type"
                rules="required"
              >
                <b-form-group :state="errors.length > 0 ? false : null">
                  <label class="required" for="saleType">{{
                    $t("ProjectForm.sell_project")
                  }}</label>

                  <v-select
                    id="saleType"
                    v-model="createProjectProfile.selectedSaleType"
                    :options="
                      $store.state.locale.locale == 'ar'
                        ? createProjectProfile.SaleTypeOptionArabic
                        : createProjectProfile.SaleTypeOption
                    "
                    :selectable="
                      (option) => !option.value.includes('select_value')
                    "
                    label="text"
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              v-if="
                createProjectProfile.selectedSaleType.value == '1' &&
                createProjectProfile.selectedCategory.value ==
                  '62b40ba9956d948f90a3941e'
              "
              md="6"
            >
              <b-row>
                <b-col md="4">
                  <validation-provider
                    #default="{ errors }"
                    name="Currency"
                    rules="required"
                  >
                    <b-form-group :state="errors.length > 0 ? false : null">
                      <label class="required" for=""
                        >{{ $t("ProjectForm.Currency") }}
                      </label>
                      <v-select
                        id=""
                        v-model="createProjectProfile.SelectedCurrency"
                        :options="currencies"
                        :selectable="
                          (option) => !option.value.includes('select_value')
                        "
                        label="text"
                      />
                      <b-form-invalid-feedback
                        :state="errors.length > 0 ? false : null"
                      >
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col md="8">
                  <validation-provider
                    #default="{ errors }"
                    name="Price"
                    rules="required|integer"
                  >
                    <b-form-group :state="errors.length > 0 ? false : null">
                      <label class="required" for="Price"
                        >{{ $t("ProjectForm.Price") }}
                      </label>

                      <b-form-input
                        id="Price"
                        v-model="createProjectProfile.Price"
                        :state="errors.length > 0 ? false : null"
                        :placeholder="$t('ProjectForm.Own_Price')"
                      />
                      <b-form-invalid-feedback
                        :state="errors.length > 0 ? false : null"
                      >
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="blockchain exchange"
                rules="required"
              >
                <b-form-group :state="errors.length > 0 ? false : null">
                  <label class="required" for="blockchain_exchange"
                    >{{ $t("ProjectForm.blockchain_exchange") }}
                  </label>
                  <v-select
                    id="blockchain_exchange"
                    v-model="createProjectProfile.selectedExchange"
                    :options="
                      $store.state.locale.locale == 'ar'
                        ? createProjectProfile.ExchangeOptionArabic
                        : createProjectProfile.ExchangeOption
                    "
                    :selectable="
                      (option) => !option.value.includes('nothing_selected')
                    "
                    label="text"
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label="$t('ProjectForm.Facebook')"
                label-for="facebook"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Facebook"
                  rules="url"
                >
                  <b-form-input
                    id="facebook"
                    v-model="createProjectProfile.facebookUrl"
                    :state="errors.length > 0 ? false : null"
                    placeholder="https://facebook.com/abc"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label="$t('ProjectForm.Instagram')"
                label-for="Instagram"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Instagram"
                  rules="url"
                >
                  <b-form-input
                    id="Instagram"
                    v-model="createProjectProfile.twitterUrl"
                    :state="errors.length > 0 ? false : null"
                    placeholder="https://Instagram.com/abc"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label="$t('ProjectForm.LinkedIn')"
                label-for="linked-in"
              >
                <validation-provider
                  #default="{ errors }"
                  name="LinkedIn"
                  rules="url"
                >
                  <b-form-input
                    id="linked-in"
                    v-model="createProjectProfile.linkedinUrl"
                    :state="errors.length > 0 ? false : null"
                    placeholder="https://linkedin.com/abc"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label="$t('ProjectForm.Website')"
                label-for="Other"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Website"
                  rules="url"
                >
                  <b-form-input
                    id="Other"
                    v-model="createProjectProfile.OtherUrl"
                    :state="errors.length > 0 ? false : null"
                    placeholder="https://other.com/abc"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="project image"
                  :rules="`required|size:${$sizeFilesProfile}`"
                >
                  <label class="required" for="main_project">{{
                    $t("ProjectForm.main_project")
                  }}</label>
                  <b-form-file
                    id="main_project"
                    v-model="createProjectProfile.icon"
                    :state="errors.length > 0 ? false : null"
                    accept="image/jpeg, image/png, image/gif"
                    :placeholder="$t('common.Choose_file_drop')"
                    :browse-text="$t('common.Browse')"
                    drop-placeholder="Drop file here..."
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="project images"
                  :rules="`size:${$sizeFilesProfile}`"
                >
                  <label for="project_images"
                    >{{ $t("ProjectForm.project_images") }}
                  </label>
                  <b-form-file
                    id="project_images"
                    v-model="createProjectProfile.images"
                    required
                    accept="image/jpeg, image/png, image/gif"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('common.Choose_file_drop')"
                    :browse-text="$t('common.Browse')"
                    drop-placeholder="Drop file here..."
                    :multiple="true"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="project documents"
                  :rules="`size:${$sizeFilesProfile}`"
                >
                  <label for="project_documents"
                    >{{ $t("ProjectForm.project_documents") }}
                  </label>
                  <b-form-file
                    id="project_documents"
                    v-model="createProjectProfile.docouments"
                    accept="application/pdf, application/vnd.ms-excel,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    required
                    :state="errors.length > 0 ? false : null"
                    multiple
                    :placeholder="$t('common.Choose_file_drop')"
                    :browse-text="$t('common.Browse')"
                    drop-placeholder="Drop file here..."
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- submit button -->
            <b-col cols="12">
              <b-alert
                v-if="errorMessage"
                show
                variant="warning"
                class="warning"
                style="color: red"
                >{{ errorMessage }}</b-alert
              >
              <br />
              <b-button
                variant="primary"
                type="submit"
                @click.prevent="validationForm"
              >
                {{ $t("ProjectForm.Submit") }}
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-form>
    </b-overlay>
    <!-- {{ filterCountry("62a09cd026bb3115ad55e5a9") }} -->
  </validation-observer>
</template>

<script>
import Treeselect from "@riophae/vue-treeselect";
// import the styles
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { mapActions, mapGetters } from "vuex";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import vue2Dropzone from "vue2-dropzone";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
// import VuePhoneNumberInput from 'vue-phone-number-input'
// import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import "../../../../layouts/landpage/ValidationFrom.js";

import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BCard,
  BFormDatepicker,
  BFormTextarea,
  BPopover,
  BFormInvalidFeedback,
  BAlert,
  BCardTitle,
  BFormFile,
  BOverlay,
} from "bootstrap-vue";
import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  digits,
  alphaDash,
  length,
} from "@validations";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BCard,
    vSelect,
    BFormFile,
    // eslint-disable-next-line vue/no-unused-components
    BFormDatepicker,
    BFormTextarea,
    vueDropzone: vue2Dropzone,
    BFormInvalidFeedback,
    BCardTitle,
    Treeselect,
    // VuePhoneNumberInput,
    ToastificationContent,
    BAlert,
    BOverlay,
  },
  data() {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const minDate = new Date(today);
    const minDate2 = new Date(today);
    minDate2.setDate(minDate2.getDate() + 1);
    return {
      show: false,
      errorMessage: "",
      projectTimeValue: "",

      minDate,
      minDate2,
      isValidNumber: null,
      dropzoneOptionsIcon: {
        addRemoveLinks: true,
        url: "https://httpbin.org/post",
        thumbnailWidth: 120,
        thumbnailHeight: 120,
        acceptedFiles: "image/*",
        maxFilesize: 3,
        maxFiles: 1,
        headers: { "My-Awesome-Header": "header value" },
      },
      dropzoneOptionsImage: {
        addRemoveLinks: true,
        url: "https://httpbin.org/post",
        thumbnailWidth: 120,
        thumbnailHeight: 120,
        acceptedFiles: "image/*",
        maxFilesize: 3,
        maxFiles: 5,
        headers: { "My-Awesome-Header": "header value" },
      },
      dropzoneOptionsProof: {
        addRemoveLinks: true,
        url: "https://httpbin.org/post",
        thumbnailWidth: 120,
        thumbnailHeight: 120,
        acceptedFiles: ".xlsx,.xls,.doc, .docx,.ppt, .pptx,.txt,.pdf",
        maxFilesize: 5,
        headers: { "My-Awesome-Header": "header value" },
      },
      createProjectProfile: {
        budgetCurrency: "",
        budgetPrice: "",
        assets: "",
        staffNumber: "",
        selectedState: null,
        name: "",
        city: "",
        selectedOwnerType: "",
        OwnerDetails: "",
        highlights: "",
        description: "",
        selectedCountry: null,
        selectedIndustry: "",
        twitterUrl: "",
        facebookUrl: "",
        OtherUrl: "",
        linkedinUrl: "",
        selectedExchange: "",
        selectedCategory: "",
        selectedSaleType: "",
        SelectedCurrency: "",
        Price: "",
        selectedStatus: "",
        Reference: "",
        start: "",
        end: "",
        icon: [],
        images: [],
        docouments: [],
        industries: [],
        // phoneNumber: '',
        // phoneCode: '',
        // email: '',
        OwnerTypeOption: [
          { value: "individual", text: "Individual" },
          { value: "group", text: "Group" },
        ],
        OwnerTypeOptionArabic: [
          { value: "individual", text: "فرد" },
          { value: "group", text: "مجموعة" },
        ],
        ExchangeOption: [
          { value: "true", text: "Yes" },
          { value: "false", text: "No" },
        ],
        ExchangeOptionArabic: [
          { value: "true", text: "نعم" },
          { value: "false", text: "لا" },
        ],
        SaleTypeOption: [
          { value: "1", text: "My Own Price" },
          { value: "2", text: "Auction" },
        ],
        SaleTypeOptionArabic: [
          { value: "1", text: "سعري الخاص" },
          { value: "2", text: "مزاد" },
        ],
        projectTimeOptions: [
          { value: "1", text: "Open" },
          { value: "2", text: "Scheduled" },
        ],
        projectTimeOptionsArabic: [
          { value: "1", text: "مفتوح" },
          { value: "2", text: "مجدول" },
        ],

        StatusOption: [
          { value: "1", text: "Complete" },
          { value: "2", text: "Incomplete" },
        ],
      },
    };
  },
  computed: {
    ...mapGetters({
      categories: "profile/getProjectCategories",
      industries: "profile/getAllIndustries",
      currencies: "currency/getCurrencies",
      countries: "country/getCountries",
      statesOptions: "country/getAllStates",
      user: "auth/user",
    }),
  },
  methods: {
    ...mapActions({
      fetchCategories: "profile/retreiveCategories",
      fetchIndustries: "profile/retreiveIndustries",
      createProject: "profile/createProject",
      fetchCurrencies: "currency/retreiveCurrencies",
      fetchCountries: "country/retreiveCountries",
      fetchStates: "country/retreiveStates",
    }),
    resetDataSaleType() {
      this.createProjectProfile.selectedSaleType = "";
      this.createProjectProfile.SelectedCurrency = "";
      this.createProjectProfile.Price = "";
    },
    normalizerArabic(node) {
      return {
        id: node.id,
        label: node.arabicName != null ? node.arabicName : node.label,
        children: node.children,
      };
    },
    normalizer(node) {
      return {
        id: node.id,
        label: node.label,
        children: node.children,
      };
    },
    handeTreeSelect(data) {
      if (data.length > 6) {
        this.createProjectProfile.industries.length = 6;
      }
    },
    filterCountry(countryId) {
      // var data = {
      //   specs: this.countries,
      // };

      var valObj = this.countries.filter(function (elem) {
        if (elem.value == countryId) return elem.text;
      });

      if (valObj.length > 0) console.log(valObj[0].text);
      // var valObj = this.countries.filter(function (elem) {
      //   if (elem.value == "62a09cd026bb3115ad55e598") return elem.Value;
      //   console.log("adasd");
      // });
      // const countriee = this.countries.find(
      //   (q, value) => value === "62a09cd026bb3115ad55e598"
      // );
      // return countriee;
      // console.log(countriee);
      // return this.countries.filter((q) =>
      //   countries.value.includes("62a09cd026bb3115ad55e598")
      // );
      // var marvelHeroes = this.countries.filter(function (hero) {
      //   return hero.value == "5";
      // });
    },

    // onUpdate(payload) {
    //   // console.log(payload);
    //   this.isValidNumber = payload.isValid
    //   this.createProjectProfile.phoneCode = payload.countryCode
    // },
    retreiveStates(data) {
      this.fetchStates(data);
    },
    removeThisFile(thisFile) {
      this.$refs.MyDropzone.removeFile(thisFile);
      console.log("File removed!");
    },
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          this.show = true;
          const ideaData = new FormData();
          ideaData.append("name", this.createProjectProfile.name);
          ideaData.append(
            "typeofOwner",
            this.createProjectProfile.selectedOwnerType.value
          );
          ideaData.append(
            "profileCountry",
            this.createProjectProfile.selectedCountry.value
          );
          ideaData.append(
            "profileIndustries",
            JSON.stringify(this.createProjectProfile.industries)
          );
          if (this.createProjectProfile.facebookUrl) {
            ideaData.append("facebook", this.createProjectProfile.facebookUrl);
          }
          if (this.createProjectProfile.twitterUrl) {
            ideaData.append("twitter", this.createProjectProfile.twitterUrl);
          }
          if (this.createProjectProfile.linkedinUrl) {
            ideaData.append("linkedIn", this.createProjectProfile.linkedinUrl);
          }
          if (this.createProjectProfile.OtherUrl) {
            ideaData.append("otherURLS", this.createProjectProfile.OtherUrl);
          }

          ideaData.append(
            "blockchainExchange",
            this.createProjectProfile.selectedExchange.value
          );
          ideaData.append(
            "category",
            this.createProjectProfile.selectedCategory.value
          );

          if (this.createProjectProfile.selectedSaleType) {
            ideaData.append(
              "saleType",
              this.createProjectProfile.selectedSaleType.value
            );
          }

          if (this.createProjectProfile.SelectedCurrency) {
            ideaData.append(
              "currencyId",
              this.createProjectProfile.SelectedCurrency.value
            );
          }
          if (this.createProjectProfile.Price) {
            ideaData.append("priceNumber", this.createProjectProfile.Price);
          }

          ideaData.append("description", this.createProjectProfile.description);
          ideaData.append(
            "detailsofOwner",
            this.createProjectProfile.OwnerDetails
          );
          ideaData.append("highlights", this.createProjectProfile.highlights);

          ideaData.append("profileCity", this.createProjectProfile.city);
          ideaData.append(
            "profileState",
            this.createProjectProfile.selectedState.value
          );
          ideaData.append("profileType", "project");
          ideaData.append("projectTime", this.projectTimeValue.value);
          ideaData.append("startAt", this.createProjectProfile.start);
          if (this.projectTimeValue.value === "2") {
            ideaData.append("endAt", this.createProjectProfile.end);
          }
          ideaData.append(
            "staffNumbers",
            this.createProjectProfile.staffNumber
          );
          ideaData.append("assetsType", this.createProjectProfile.assets);
          ideaData.append(
            "budgetAmount",
            this.createProjectProfile.budgetPrice
          );
          ideaData.append(
            "budgetCurrencyId",
            this.createProjectProfile.budgetCurrency.value
          );

          ideaData.append("profileIcon", this.createProjectProfile.icon);

          if (this.createProjectProfile.images) {
            for (const i of Object.keys(this.createProjectProfile.images)) {
              ideaData.append(
                "profileImages",
                this.createProjectProfile.images[i]
              );
            }
          }

          if (this.createProjectProfile.docouments) {
            for (const i of Object.keys(this.createProjectProfile.docouments)) {
              ideaData.append(
                "profileDocuments",
                this.createProjectProfile.docouments[i]
              );
            }
          }

          // eslint-disable-next-line no-underscore-dangle
          ideaData.append("userId", this.user._id);
          this.createProject(ideaData)
            .then((response) => {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: this.$t("messages.Added_successfully"),
                  icon: "CoffeeIcon",
                  variant: "success",
                },
              });
              this.show = false;
              this.$router.push({ name: "project-listAll" });
            })
            .catch((error) => {
              this.show = false;
              this.errorMessage = error.response.data.error.message;
            });
        }
      });
    },
  },
  mounted() {
    this.fetchCategories();
    this.fetchIndustries();
    this.fetchCurrencies();
    this.fetchCountries();
    this.configValidate();
  },
};
</script>

<style type="text/css">
@charset "UTF-8";

[dir] label {
  font-size: 14px;
}
[dir] .form-control {
  padding: 0.438rem 1rem;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  font-size: 15px;
}

[dir] .vue-form-wizard .wizard-card-footer .wizard-footer-right .wizard-btn {
  background-color: #366ce3;
  border-color: #2d5dc7;
}
[dir] .vs__selected {
  background-color: #366ce3;
}
.col-md-6 {
  margin-bottom: 5px;
}

.required:after {
  content: " *";
  color: red;
}
[dir] .dropzone.dz-clickable {
  cursor: pointer;
  min-height: 150px;
  max-height: 260px;
  overflow-y: scroll;
}
</style>
